// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {fetchSafeDocumentFragment} from '../fetch'
import {requestSubmit} from '@github-ui/form-utils'

// When the user creates a new label, update the list of available labels
//to choose from, so the user can immediately apply their new label.
on(
  'submit',
  '.js-create-runner-label-form',
  async function (event: SubmitEvent) {
    event.preventDefault()

    let response
    const form = event.currentTarget as HTMLFormElement
    const labelsMenu = form.closest<HTMLElement>('.js-runner-labels-menu')!
    const errorContainer = labelsMenu.querySelector<HTMLElement>('.js-runner-label-error')!

    try {
      response = await fetchSafeDocumentFragment(document, form.action, {
        method: form.method,
        body: new FormData(form),
      })
    } catch (error) {
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (error.response.json?.message && errorContainer) {
        // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
        errorContainer.textContent = error.response.json.message
        errorContainer.hidden = false
      }

      return
    }

    if (!response) return

    if (errorContainer) {
      errorContainer.hidden = true
      errorContainer.textContent = ''
    }

    const input = response.querySelector<HTMLInputElement>('.js-label-input')
    const list = labelsMenu.querySelector<HTMLElement>('.js-filterable-runner-labels')!
    list.prepend(response)

    // Select added label. This will trigger any listeners for label selection as well
    input?.click()

    const field = labelsMenu.querySelector<HTMLInputElement>('.js-label-filter-field')!
    field.value = ''
    field.focus()
  },
  {capture: true},
)

function submitOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const form = currentTarget.querySelector<HTMLFormElement>('.js-runner-labels-form')!
    requestSubmit(form)
  }
}

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', submitOnMenuClose, {once: true})
  },
  {capture: true},
)
