import memoize from '@github/memoize'
import {
  FetchDataEvent,
  FilterItem,
  FilterProvider,
  QueryEvent,
  QueryFilterElement,
} from '@github-ui/query-builder-element/query-builder-api'
import {QueryBuilderElement} from '@github-ui/query-builder-element'

import {hasMatch, score} from 'fzy.js'

interface Suggestion {
  value: string
  name?: string
  description?: string
}

type BaseProviderProps = {
  queryBuilder: QueryBuilderElement
  name: string
  value: string
  description: string
  priority: number
  fetchSuggestions: () => Promise<Suggestion[]>
}

const memoizeCache = new Map()
const memoizeFetchJSON = memoize(fetchJSON, {cache: memoizeCache})

async function fetchJSON(url: string) {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})

  if (response.ok) {
    return await response.json()
  } else {
    return undefined
  }
}

function getSearchInput(): HTMLElement {
  return document.querySelector<HTMLElement>('query-builder#query-builder-ruleset-criteria-input-combobox')!
}

async function fetchSeveritySuggestions(): Promise<Suggestion[]> {
  return JSON.parse(getSearchInput().getAttribute('data-suggestable-severities')!)
}

async function fetchPackageSuggestions(): Promise<Suggestion[]> {
  const url = getSearchInput().getAttribute('data-suggestable-packages-path')!
  return memoizeFetchJSON(url)
}

async function fetchScopeSuggestions(): Promise<Suggestion[]> {
  return JSON.parse(getSearchInput().getAttribute('data-suggestable-scopes')!)
}

async function fetchEcosystemSuggestions(): Promise<Suggestion[]> {
  const url = getSearchInput().getAttribute('data-suggestable-ecosystems-path')!
  return memoizeFetchJSON(url)
}

async function fetchManifestSuggestions(): Promise<Suggestion[]> {
  const url = getSearchInput().getAttribute('data-suggestable-manifests-path')!
  return memoizeFetchJSON(url)
}

async function fetchCWESuggestions(): Promise<Suggestion[]> {
  return JSON.parse(getSearchInput().getAttribute('data-suggestable-cwes')!)
}

function filterSuggestions(suggestions: Suggestion[], query: string): Suggestion[] {
  // no need to filter if we don't have a query
  if (query === '') return suggestions

  const filteredSuggestions = suggestions.filter(suggestion => {
    if (hasMatch(query, suggestion.value)) return suggestion
    if (suggestion.description != null && hasMatch(query, suggestion.description)) return suggestion
  })

  filteredSuggestions.sort((a, b) => {
    const aValueMatch = hasMatch(query, a.value)
    const bValueMatch = hasMatch(query, b.value)

    // we want to prefer values matches over description matches
    // if both suggestions have a value match, we use score to sort
    if (aValueMatch && bValueMatch) {
      return score(query, a.value) - score(query, b.value)
    } else if (aValueMatch && !bValueMatch) {
      // prefer a's value since it matches
      return -1
    } else if (!aValueMatch && bValueMatch) {
      //  prefer b's value since it matches
      return 1
    } else {
      // if neither suggestion has a value match that implies that both are matched on description
      return score(query, a.description!) - score(query, b.description!)
    }
  })

  // return in descending order
  return filteredSuggestions.reverse()
}

class BaseProvider extends EventTarget implements FilterProvider {
  type = 'filter' as const
  protected queryBuilder: QueryBuilderElement
  readonly name: string
  readonly value: string
  readonly singularItemName: string
  readonly description: string
  readonly priority: number
  readonly fetchSuggestions: () => Promise<Suggestion[]>

  constructor({
    name = '',
    value = '',
    description = '',
    priority = 0,
    queryBuilder,
    fetchSuggestions,
  }: BaseProviderProps) {
    super()

    this.queryBuilder = queryBuilder
    this.name = name
    this.value = value
    this.description = description
    this.singularItemName = value
    this.priority = priority
    this.fetchSuggestions = fetchSuggestions

    this.queryBuilder.attachProvider(this)
    this.queryBuilder.addEventListener('query', this)
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const suggestions = await this.fetchSuggestions()
    this.dispatchEvent(new FetchDataEvent(this.fetchSuggestions()))

    const filteredSuggestions = filterSuggestions(suggestions, lastQueryValue)

    // The search filter has a performance bug triggered by the number of suggestions we return
    // The fix for now is to limit the number of suggestions to a smallish number
    // See https://github.com/github/discussions/issues/2816
    for (const suggestion of filteredSuggestions.slice(0, 10)) {
      const filterItem = new FilterItem({
        filter: this.singularItemName,
        name: suggestion.name,
        value: suggestion.value.replace(/"/g, ''), // Remove quotes for some reason, unclear why we need this
        description: suggestion.description,
        priority: 1,
      })
      this.dispatchEvent(filterItem)
    }
  }
}

class SeverityProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Severities',
      value: 'severity',
      description: 'critical, high, moderate, low',
      priority: 1,
      fetchSuggestions: fetchSeveritySuggestions,
    })
  }
}

class PackageProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Packages',
      value: 'package',
      description: 'package-name',
      priority: 2,
      fetchSuggestions: fetchPackageSuggestions,
    })
  }
}

class EcosystemProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Ecosystems',
      value: 'ecosystem',
      description: 'ecosystem-name',
      priority: 3,
      fetchSuggestions: fetchEcosystemSuggestions,
    })
  }
}

class ScopeProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Scopes',
      value: 'scope',
      description: 'runtime, development',
      priority: 4,
      fetchSuggestions: fetchScopeSuggestions,
    })
  }
}

class ManifestProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Manifests',
      value: 'manifest',
      description: 'manifest-name',
      priority: 5,
      fetchSuggestions: fetchManifestSuggestions,
    })
  }
}

class CWEProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'CWEs',
      value: 'cwe',
      description: 'cwe-number',
      priority: 6,
      fetchSuggestions: fetchCWESuggestions,
    })
  }
}

document.addEventListener('query-builder:request-provider', (event: Event) => {
  new SeverityProvider(event.target as QueryBuilderElement)
  new PackageProvider(event.target as QueryBuilderElement)
  new EcosystemProvider(event.target as QueryBuilderElement)
  new ScopeProvider(event.target as QueryBuilderElement)
  new ManifestProvider(event.target as QueryBuilderElement)
  new CWEProvider(event.target as QueryBuilderElement)
})
