import {controller, target} from '@github/catalyst'
import {QueryBuilderElement} from '@github-ui/query-builder-element'
@controller
class DependabotAlertRulesetFormElement extends HTMLElement {
  @target name: HTMLInputElement
  @target rule: HTMLInputElement
  @target ruleOptions: HTMLElement

  connectedCallback() {
    this.toggleRuleCheckbox()
    this.focusAlertCriteriaIfInvalid()
  }

  toggleRuleCheckbox() {
    this.ruleOptions.hidden = !this.rule.checked
  }

  focusAlertCriteriaIfInvalid() {
    const queryBuilderInput = <HTMLInputElement>this.querySelector('query-builder input')
    if (queryBuilderInput.hasAttribute('invalid')) {
      // for the moment the nameField is the only field that is before this field, therefore
      // we can assume that the queryBuilder is the first field that is invalid if the nameField is valid
      if (!this.name.hasAttribute('invalid')) {
        // we are (ab)using the `query-builder:request-provider` event to determine when the query builder
        // is ready to be focused as it's not fully initialized during this callback
        // https://github.com/github/dependabot-alerts/issues/1508
        let focused = false
        document.addEventListener('query-builder:request-provider', (event: Event) => {
          // return unless the event is for our our query builder
          const eventTarget = <QueryBuilderElement>event.target
          if (!eventTarget || eventTarget.input.id !== queryBuilderInput.id) return

          // since this event can be fired multiple times, only focus on the first
          if (!focused) {
            focused = true
            queryBuilderInput.focus()
          }
        })
      }
    }
  }
}
